<script setup lang="ts">
import { SpeedInsights } from '@vercel/speed-insights/nuxt'

const { $httpCookies } = useNuxtApp()
const { setLastWishlistTokenActive } = useMultipleWishlist()
const { locale } = useI18n()
const { windowSizeChanged } = useBreakpoint()
const config = useRuntimeConfig()
const modalCount = useModalCount()
const {
  linksCanonicalAndAlternate,
  linksCanonicalAndAlternatePrivatevillapreview,
} = useSeoLinks()
const { notifications, removeNotifications } = useNotifications()
const route = useRoute()
const { setRoute, setInspirationsLinks } = useHeaderNavigation()
const { user } = useUser()
const { fetchUserPropertiesByLocation } = useUserProperties()

const experimentDebug = computed(() => {
  const debug = useCookie('experiment_debug')
  return Boolean(debug.value)
})

const links = computed(() => {
  const privatevillapreviewPage = route.name
    ?.toString()
    ?.includes('screenshot-whitebrand')
  const disableLinks = route.name?.toString()?.includes('stayProposal-token')

  if (privatevillapreviewPage) {
    return linksCanonicalAndAlternatePrivatevillapreview.value
  }

  if (disableLinks) return []

  return linksCanonicalAndAlternate.value
})

// Handle collections Header
await setInspirationsLinks()

// handle header theme
setRoute(route.name?.toString() || '')

const lastWishlistActive = $httpCookies.get('user_wishlist_token')
if (lastWishlistActive) setLastWishlistTokenActive(lastWishlistActive)

await useAsyncData('userGlobalInformations', async () => {
  //Handle localisation user Info
  await fetchUserPropertiesByLocation()
  return true
})

onBeforeMount(() => {
  window.addEventListener('resize', windowSizeChanged)
})

onBeforeUnmount(() => {
  window.removeEventListener('resize', windowSizeChanged)
})

onMounted(async () => {
  await fetchUserPropertiesByLocation()
})

useHead({
  title: config.public.siteName,
  titleTemplate: `%s - ${config.public.siteName}`,
  htmlAttrs: {
    lang: locale.value,
  },
  meta: [
    { charset: 'utf-8' },
    { content: 'IE=edge,chrome=1', 'http-equiv': 'X-UA-Compatible' },
    { name: 'viewport', content: 'width=device-width, initial-scale=1' },
    { hid: 'description', name: 'description', content: 'Meta description' },
    {
      name: 'theme-color',
      content: '#202020',
    },
  ],
  link: [
    // { rel: "shortcut icon", type: "image/x-icon", href: "favicon.png" },
    ...links.value,
  ],
})

// Handle modal lock scroll
watch(modalCount, (value) => {
  if (value > 0) {
    useLockBody(true)
  } else {
    useLockBody(false)
  }
})
</script>

<template>
  <div>
    <NuxtLoadingIndicator color="#EBDECA" />

    <NuxtLayout>
      <NuxtPage />
    </NuxtLayout>

    <SpeedInsights v-if="config.public.enableVercel" />

    <ABDebug v-if="experimentDebug" />

    <ThePopinCglPartner v-if="user.partnershipAgency" />

    <template v-if="notifications.length">
      <TheToastNotification
        v-for="toast in notifications"
        :key="toast.id"
        :message="toast.message"
        :duration="toast.duration"
        :link="toast.link"
        :show-close-button="toast.showCloseButton"
        :title="toast.title"
        :toast-class="toast.toastClass"
        :type="toast.type"
        @close="removeNotifications(toast.id)"
      />
    </template>
  </div>
</template>

<style>
.page-enter-active,
.page-leave-active {
  transition: all 0.4s;
}
.page-enter-from,
.page-leave-to {
  opacity: 0;
  filter: blur(1rem);
}
</style>
